import React, { Component } from 'react';
import Spinner from './spinner';

class AccountView extends Component {

  async componentDidMount() {
    if (this.props.account) {
      this.setState(
          {view: await this.loadAccountComponent(this.props.account)});
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.account !== this.props.account) {
      this.setState({view: await this.loadAccountComponent(this.props.account)});
    }
  }

// Load the account views asynchronously to reduce the initial load time of the app
  // and prevent loading unnecessary modules
  async loadAccountComponent(account) {
    const {orgId} = this.props;

    switch (account.accountType) {
      case 'Household':
        return await import('../account_types/4h/household')
            .then(m => m.default);
      case 'SysAdmin':
        return await import('../account_types/sys_admin')
            .then(m => m.default);
      case 'StateAdmin':
        return await import('../account_types/4h/state_admin')
            .then(m => m.default);
      case 'StateUser':
        return await import('../account_types/4h/state_user')
            .then(m => m.default);
      case 'DistrictAdmin':
        return await import('../account_types/4h/district_admin')
            .then(m => m.default);
      case 'CountyAdmin':
        return await import('../account_types/4h/county_admin')
            .then(m => m.default);
      default:
        return () => <p>Unknown account type {account.accountType}</p>;
    }
  }

  render() {
    const {view: View = Spinner} = this.state || {};
    return <View/>;
  }
}

export default AccountView;