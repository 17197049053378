// Shared helper functions
import moment from 'moment';
import BigNumber from "bignumber.js";

/**
 * Flattens a multi-level array. We use this instead of the Arrays.flatMap() function because this works on Edge
 *
 * @param {*[]} array - Array to be flattened
 * @returns {*[]}
 */
export function flatMap(array) {
  if (Array.isArray(array)) {
    return array.reduce((t, a) => t.concat(flatMap(a)), []);
  } else {
    return array;
  }
}

/**
 * Gets the 4H year based on the given date and the start of the year
 *
 * @param {string|Date|number|moment.Moment} date
 * @param {string|Date|number|moment.Moment?} [startOfYear='08/02']
 * @returns {number}
 */
export function get4HYear(date, startOfYear) {
  const mDate = moment(date).startOf('day');
  const startOf4HYear = startOfYear || moment(date).month(8).day(1);
  if (mDate.isBefore(startOf4HYear)) {
    return mDate.add(-1, 'year').year();
  } else {
    return mDate.year();
  }
}

/**
 * Returns the current 4H year based on the state settings
 *
 * @param {StateSettings?} stateSettings
 * @returns {number}
 */
export function current4HYear(stateSettings) {
  if (stateSettings) {
    return get4HYear(moment.now(), moment([
      moment().year(),
      stateSettings.yearStartMonth, stateSettings.yearStartDay]));
  } else {
    return get4HYear(moment.now());
  }
}

export function formatEnrollmentAge(stateSettings, enrollmentYear) {
  const fourHAgeMonth = stateSettings.fourHAgeAsOfMonth || "08",
      fourHAgeDay = stateSettings.fourHAgeAsOfDay || "01";
  const fourHAgeYear = enrollmentYear + (stateSettings.fourHAgeAsOfYear || 0);
  return fourHAgeMonth + "/" + fourHAgeDay + "/" + fourHAgeYear
}

/* Gets the status and formats it to proper text */
export function formatEnrollmentStatus(status) {
  switch (status) {
    case 'PendingStateApproval':
      status = 'Pending State Approval';
      break;

    case 'InProgress':
      status = 'In Progress';
      break;

    case 'PendingApproval':
      status = 'Pending Approval';
      break;

    case 'Active':
      status = 'Active';
      break;

    case 'PendingPayment':
      status = 'Pending Payment';
      break;

    case 'Canceled':
      status = 'Canceled';
      break;

    case 'Returned':
      status = 'Returned';
      break;

    case 'Ineligible':
      status = 'Ineligible';
      break;

    default:
      status = '';
      break;

  }
  return status

}

/* Gets the member type and formats it to proper text */
export function formatMemberType(memberType) {
  switch ((memberType || '').toUpperCase()) {
    case 'CLUBMEMBER':
      memberType = 'Club Member';
      break;
    case 'VOLUNTEER':
      memberType = 'Volunteer';
      break;
    case 'CLUBLEADER':
      memberType = 'Club Leader';
      break;
    case 'PROJECTLEADER':
      memberType = 'Project Leader';
      break;
    case 'VETTEDVOLUNTEER':
      memberType = 'Vetted Volunteer';
      break;
    case 'RETURNINGVOLUNTEER':
      memberType = 'Returning Volunteer';
      break;
    case 'STATEADMIN':
      memberType = 'State Admin';
      break;
    case 'COUNTYADMIN':
      memberType = 'County Admin';
      break;
    case 'DISTRICTADMIN':
      memberType = 'County Admin';
      break;
    case 'SYSADMIN':
      memberType = 'System Admin';
      break;
    default:
      break;
  }
  return memberType;
}


/* Gets the organization and formats it to proper text */
export function formatOrganization(organization) {
  switch ((organization || '').toUpperCase()) {
    case '4H':
      organization = '4-H';
      break;
    case 'FFA':
      organization = 'FFA';
      break;
    case 'NSS':
      organization = 'National Shooting Sports';
      break;
    case 'FSF':
      organization = 'Florida State Fair';
      break;
    case 'SASSR':
      organization = 'San Antonio Stock Show and Rodeo';
      break;
    default:
      break;
  }
  return organization;
}

/**
 * Formats a year into a year span for showing a 4H year
 *
 * @param {string|number} year - 4H year
 * @returns {string}
 *
 * @example
 * format4HYear('2019')
 * // returns '2019-2020'
 */
export function format4HYear(year) {
  year = parseInt(year);
  return year + ' - ' + (year + 1);
}

/**
 * Returns a formatted current4HYear
 *
 * @param {StateSettings?} stateSettings
 * @param {number?} [year=undefined]
 * @returns {string}
 */
export function formatCurrent4HYear(stateSettings, year = undefined) {
  year = year || current4HYear(stateSettings);
  return year + ' - ' + moment().year(year).add(1, 'year').year();
}

/**
 * Gets a local URL for a random avatar
 *
 * @returns {string}
 */
export function randomProfileUrl() {
  const num = 1 + Math.floor(Math.random() * Math.floor(25));
  return `/avatars/av${num}.jpg`;
}

/**
 * Returns an object based on an array of label/value pairs
 *
 * @param {{label: string, value: *}[]} [arr=[]]
 * @returns {Object}
 *
 * @example
 * labelValueArrayToObj([{label: 'JO', value: 12}])
 * // returns {'12': 'Jo'}
 */
export function labelValueArrayToObj(arr = []) {
  return arr.reduce((o, lv) => ({...o, [lv.value]: lv.label}), {});
}

/**
 * Takes an array of strings and returns an object with each label/value being a string
 *
 * @param {string[]} arr - Array of label/values
 * @returns {Object}
 *
 * @example
 * labelArrayToObj(['one'])
 * // returns {'one': 'one'}
 */
export function labelArrayToObj(arr = []) {
  return arr.reduce((o, l) => ({...o, [l]: l}), {});
}

/**
 * Joins two Paths/URLs together
 *
 * @param {string} a - First Path/URL
 * @param {string} b - Second Path/URL
 * @returns {string}
 *
 * @example
 * joinPath('/a/', '/b/')
 * // returns '/a/b/'
 */
export function joinPath(a, b) {
  if (!a && a !== 0) return b;
  if (!b && b !== 0) return a;
  return (a + '/' + b).replace(/\/+/g, '/');
}

export async function getImageAsDataUrl(url) {
  if (url && url.startsWith('data:')) {
    return url;
  }
  if (/\.(heic|pdf|mov|pptx?)$/i.test(url)) {
    return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
  }
  const blob = await fetch(url).then(r => {
    if (!r.ok) {
      throw new Error('Not Found: ' + url);
    } else {
      return r.blob();
    }
  });
  return await new Promise((res, rej) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      res(reader.result);
    };
    reader.onerror = rej;
    reader.readAsDataURL(blob);
  });
}

/**
 * Loads a script from a URL
 *
 * @param {string} script - The URL of the script
 * @param {?boolean} replaceExisting - checks if script already exists, and deletes it if it does
 * @returns {Promise<*>}
 */
export function loadScriptTag(script, replaceExisting = false) {
  return new Promise((res, rej) => {
    const scriptEl = document.head.querySelector(
        'script[src="' + script + '"]');
    if (scriptEl && replaceExisting) {
      scriptEl.remove();
    } else if (scriptEl) {
      return window.setTimeout(res, 1000);
    }
    const ele = document.createElement('script');
    ele.setAttribute('type', 'text/javascript');
    ele.setAttribute('src', script);
    ele.onload = res;
    ele.onerror = rej;
    document.head.appendChild(ele);
  });
}

/**
 * Loads a stylesheet from a URL
 *
 * @param {string} style - The URL of the stylesheet
 */
export function loadStyleTag(style) {

  const styleEl = document.head.querySelector(
      'link[href="' + style + '"]');
  if (styleEl) {
    return;
  }
  const ele = document.createElement('link');
  ele.setAttribute('rel', 'stylesheet');
  ele.setAttribute('type', 'text/css');
  ele.setAttribute('src', style);
  document.head.appendChild(ele);
}

export function resolveRelativePath(path) {
  const prefix = process.env.REACT_APP_ROOT_PATH;
  return ((prefix ? (prefix + '/') : '') + path).replace(/(\/+)/g, '/');
}

/**
 * Sums a numeric property from objects in the array
 *
 * @param {T[]} array
 * @param {string} field
 * @returns {T}
 */
export function sum(array, field) {
  return (array || []).reduce(
      (acc, element) => acc + (parseFloat(element[field]) || 0), 0);
}

/**
 * Partitions an array into smaller arrays based on the size
 *
 * @param {T[]} array
 * @param {number} size
 * @param {?any} emptyValue
 * @returns {T[][]}
 *
 * @example
 * partition([1, 2, 3, 4, 5, 6, 7], 3)
 * // returns [[1,2,3], [4,5,6], [7,null,null]]
 */
export function partition(array, size, emptyValue = null) {
  const newArray = [];
  let newGroup = [];
  for (let i = 0; i < array.length; i++) {
    if (newGroup.length >= size) {
      newArray.push(newGroup);
      newGroup = [];
    }
    newGroup.push(array[i]);
  }
  if (newGroup.length) {
    for (let i = 0; i < size; i++) {
      newGroup[i] = newGroup[i] || emptyValue;
    }
    newArray.push(newGroup);
  }
  return newArray;
}

/**
 * formats a string or number into a money string
 *
 * @param {number|string|undefined|null} x
 * @returns {string}
 */
export function formatMoney(x) {
  if (x === null || x === undefined) {
    x = 0;
  }
  if (typeof x === 'string') {
    x = parseFloat(x);
  }
  return !isNaN(x) ? `$${numberWithCommas(x.toFixed(2))}` : '';
}

/**
 * formats a string into a date
 *
 * @param {string|Date} x
 * @returns {string}
 */
export function formatDate(x) {
  if (!x) {
    return '';
  }
  return moment(x).format('MM-DD-YYYY');
}

/**
 * formats a string into a datetime
 *
 * @param {string|Date} x
 * @returns {string}
 */
export function formatDateTime(x) {
  if (!x) {
    return '';
  }
  return moment(x).format('M/D/YYYY hh:mm A');
}

/**
 * formats a string into a year
 *
 * @param {string|Date} x
 * @returns {string}
 */
export function formatYear(x) {
  if (!x) {
    return '';
  }
  return moment(x).format('YYYY');
}

/**
 * Formats a number into a weight and adds 'lbs' to the end
 * Strings are attempted to parse into a number
 *
 * @param {number|string} x
 * @returns {string}
 */
export function formatWeight(x) {
  if (typeof x === 'string') {
    x = parseFloat(x);
  }
  return !isNaN(x) ? `${x.toFixed(2)} lbs` : '';
}

// field = string (name of field)
// arr = array of rows ex: inventoryitem
// opts = object of desc and date
/**
 * Sorts an array of objects by a specified field
 *
 * @param {T[]} array - The array of objects to be sorted
 * @param {string} field - The name of the field
 * @param {Object} [opts={}] - Optional options
 * @param {boolean} [opts.date] - Determines whether or not the field values are dates
 * @param {boolean} [opts.desc] - Determines if the sorting order is descending or not
 * @returns {*}
 */
export function sortBy(field, array, opts = {}) {
  const {date, desc} = opts;
  return array.sort(function (_a, _b) {
    let a = _a, b = _b;
    if (desc) {
      a = _b;
      b = _a;
    }
    if (date) {
      return moment(a[field]).diff(moment(b[field]), 'minutes');
    }
    return a[field].toString().localeCompare(b[field].toString());
  });
}

/**
 * Calculates the amount of depreciation given the number of years and initial cost
 *
 * @param {number} years - The number of years to calculate
 * @param {number} cost - The initial cost
 * @returns {number}
 */
function calcDep(years, cost) {
  return Math.max(0, years * cost * 0.1);
}

// Compute inventory depreciation given a project and
// an array of inventoryItems
export function computeDepreciation(project) {
  return inventoryItems =>
      sortBy('acquiredOn', inventoryItems.map(inventoryItem => {
        const cost = inventoryItem.purchaseCost || 0,
            acquiredY = moment(inventoryItem.acquiredOn).year(),
            startY = moment(project.startsOn || undefined).year(),
            endY = Math.max(moment(project.endsOn || undefined).year(),
                startY + 1),
            startValue = cost - calcDep(startY - acquiredY - 1, cost),
            dep = calcDep(endY - startY, cost),
            endValue = startValue - dep,
            currentValue = endValue;

        return Object.assign({}, inventoryItem,
            {dep, startValue, endValue, currentValue});
      }, {date: true}));
}

export function breakeven(project) {
  return inventoryItems =>
      sortBy('acquiredOn', inventoryItems.map(inventoryItem => {
        const totalExpenses = inventoryItem.totalExpenses || 0,
            finalWeight = inventoryItem.finalWeight || 0,
            breakeven = totalExpenses / finalWeight;
        return Object.assign({}, inventoryItem, {breakeven});
      }, {date: true}));
}

export function formatDateRange(start, end) {
  const mStart = moment(start), mEnd = moment(end);
  if (mStart.diff(end, 'days') === 0) {
    return mStart.format('MMM Do h:mma') + ' - ' + mEnd.format('h:mma');
  }
  return mStart.format('MMM Do h:mma') + ' - ' + mEnd.format('MMM Do h:mma');
}

/**
 * Returns unique elements in an array
 *
 * @param {T[]} a - Array of elements
 * @returns {T[]}
 */
export function uniq(a = []) {
  const seen = {};
  return a.filter(function (item) {
    return seen.hasOwnProperty(item) ? false : (seen[item] = true);
  });
}

/**
 * Turns an input string into camelCase
 *
 * @param {string} str - String to replace
 * @returns {string}
 */
export function toCamelCase(str) {
  return str.toString().toLowerCase()
      .replace(/[^a-z0-9 ]/ig, '')
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
        if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
        return index === 0 ? (match || '').toLowerCase() : (match || '').toUpperCase();
      });
}

/**
 * Turns an input string into kebab-case
 *
 * @param {string} str - String to replace
 * @returns {string}
 */
export function toKebabCase(str) {
  return str.trim().toLowerCase().replace(/\s+/g, '-')
      .replace(/-+/g, '-');
}

/**
 * Loads a stripe script instance into the HTML DOM
 *
 * @param {BackendClient} backendClient
 * @returns {Promise<*|void>}
 */
export function loadStripeInstance(backendClient) {
  return new Promise((resolve, reject) => {
    if (window.stripeInstance) {
      resolve(window.stripeInstance);
    } else {
      backendClient.getStripePublicKey().then(stripePublicKey => {
        const el = document.querySelector('#stripe-js');
        if (!el) {
          const newScript = document.createElement('script');
          newScript.id = 'stripe-js';
          newScript.src = 'https://js.stripe.com/v3/';
          newScript.onload = () => {
            // Create Stripe instance once Stripe.js loads
            window.stripeInstance = window.Stripe(stripePublicKey);
            resolve(window.stripeInstance);
          };
          newScript.onerror = reject;
          document.body.append(newScript);
        }
      }, reject);
    }
  });
}

// Stripe fee is currently 2.9% + $0.30
const STRIPE_PERCENT = new BigNumber(0.029);
const STRIPE_FIXED = new BigNumber(0.3);

// Compute the stripe fee such for a given amount, including the recursive fee on the fee,
// such that the remaining amount after stripe's fee is the original amount
export function computeStripeFee(amount) {
  // amount + stripeFee = total
  // stripeFee = 2.9% * total + $0.30
  // amount + (2.9% * total) + $0.30 = total
  // amount + (2.9% * total) - total + $0.30 = 0
  // amount + total * (2.9% - 1) + $0.30 = 0
  // amount + total * -0.971 + 0.30 = 0
  // total * -0.971 + 0.30 = -amount
  // total * -0.971 = -amount - 0.3
  // total = (-amount - 0.3) / -0.971
  // total = (amount + 0.3) / 0.971
  // stripeFee = ((amount + 0.3) / 0.971) * 0.029 + 0.3
  // stripeFee = (((amount + 0.3) * 0.029) / 0.971) + 0.3
  return new BigNumber(amount).plus(STRIPE_FIXED).times(STRIPE_PERCENT)
          .dividedBy(new BigNumber(1).minus(STRIPE_PERCENT))
          .plus(STRIPE_FIXED).toNumber();
}

/**
 * Wraps a string with newLines based on a specified width
 *
 * @param {string} str
 * @param {number} maxWidth
 * @returns {string}
 */
export function wordWrap(str, maxWidth) {
  var newLineStr = '\n', res = '';

  function testWhite(x) {
    var white = new RegExp(/^\s$/);
    return white.test(x.charAt(0));
  }

  while (str.length > maxWidth) {
    let found = false;
    // Inserts new line at first whitespace of the line
    for (let i = maxWidth - 1; i >= 0; i--) {
      if (testWhite(str.charAt(i))) {
        res = res + [str.slice(0, i), newLineStr].join('');
        str = str.slice(i + 1);
        found = true;
        break;
      }
    }
    // Inserts new line at maxWidth position, the word is too long to wrap
    if (!found) {
      res += [str.slice(0, maxWidth), newLineStr].join('');
      str = str.slice(maxWidth);
    }
  }

  return res + str;
}

/**
 * Pads a number with a character based on a specified number of digits needed
 *
 * @param {number} num - Number to pad with the character
 * @param {number} len - Number of digits needed
 * @param {string} [c='0'] - Padding character
 * @returns {string}
 */
export function leftPad(num, len, c = '0') {
  if (num !== 0 && !num) return '';
  let s = num.toString();
  while (s.length < len) s = c + s;
  return s;
}

/**
 * Pluralizes a few different strings
 *
 * @param {string} str
 * @returns {string}
 */
export function pluralize(str) {
  let finalString;
  switch (str) {
    case 'County':
      finalString = 'Counties';
      break;
    case 'Youth':
      return 'Youth';
    default:
      finalString = str + 's';
  }

  return finalString;
}

/**
 * creates a function that will wait the call
 * to the passed function until after a given
 * amount of time (msDelay). If the function
 * is called again before the delay time then
 * the function is not called, but rescheduled.
 *
 * copied from lodash (https://github.com/lodash/lodash)
 * @param {Function} func - function to call
 * @param {number} wait - delay time in ms
 * @param {?Object} [_this=undefined] - this to apply to function
 * @returns {Function}
 */
export function debounce(func, wait, _this = undefined) {
  var lastArgs,
      maxWait,
      result,
      timerId,
      lastCallTime,
      lastInvokeTime = 0,
      leading = false,
      maxing = false,
      trailing = true;

  if (typeof func != 'function') {
    throw new TypeError(func + ' is not a function');
  }
  wait = parseInt(wait) || 0;
  /*if (isObject(options)) {
    leading = !!options.leading;
    maxing = 'maxWait' in options;
    maxWait = maxing ? nativeMax(toNumber(options.maxWait) || 0, wait) : maxWait;
    trailing = 'trailing' in options ? !!options.trailing : trailing;
  }*/

  function invokeFunc(time) {
    var args = lastArgs;

    lastArgs = undefined;
    lastInvokeTime = time;
    result = func.apply(_this, args);
    return result;
  }

  function leadingEdge(time) {
    // Reset any `maxWait` timer.
    lastInvokeTime = time;
    // Start the timer for the trailing edge.
    timerId = setTimeout(timerExpired, wait);
    // Invoke the leading edge.
    return leading ? invokeFunc(time) : result;
  }

  function remainingWait(time) {
    var timeSinceLastCall = time - lastCallTime,
        timeSinceLastInvoke = time - lastInvokeTime,
        timeWaiting = wait - timeSinceLastCall;

    return maxing
        ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke)
        : timeWaiting;
  }

  function shouldInvoke(time) {
    var timeSinceLastCall = time - lastCallTime,
        timeSinceLastInvoke = time - lastInvokeTime;

    // Either this is the first call, activity has stopped and we're at the
    // trailing edge, the system time has gone backwards and we're treating
    // it as the trailing edge, or we've hit the `maxWait` limit.
    return (lastCallTime === undefined || (timeSinceLastCall >= wait) ||
        (timeSinceLastCall < 0) || (maxing && timeSinceLastInvoke >= maxWait));
  }

  function timerExpired() {
    var time = Date.now();
    if (shouldInvoke(time)) {
      return trailingEdge(time);
    }
    // Restart the timer.
    timerId = setTimeout(timerExpired, remainingWait(time));
  }

  function trailingEdge(time) {
    timerId = undefined;

    // Only invoke if we have `lastArgs` which means `func` has been
    // debounced at least once.
    if (trailing && lastArgs) {
      return invokeFunc(time);
    }
    lastArgs = undefined;
    return result;
  }

  function cancel() {
    if (timerId !== undefined) {
      clearTimeout(timerId);
    }
    lastInvokeTime = 0;
    lastArgs = lastCallTime = timerId = undefined;
  }

  function flush() {
    return timerId === undefined ? result : trailingEdge(Date.now());
  }

  function debounced() {
    var time = Date.now(),
        isInvoking = shouldInvoke(time);

    lastArgs = arguments;
    lastCallTime = time;

    if (isInvoking) {
      if (timerId === undefined) {
        return leadingEdge(lastCallTime);
      }
      if (maxing) {
        // Handle invocations in a tight loop.
        timerId = setTimeout(timerExpired, wait);
        return invokeFunc(lastCallTime);
      }
    }
    if (timerId === undefined) {
      timerId = setTimeout(timerExpired, wait);
    }
    return result;
  }
  debounced.cancel = cancel;
  debounced.flush = flush;
  return debounced;
}

/**
 * Does the same thing debounce does, but it returns a value
 * @param {function(...args): T} fn - function to call
 * @param {number} msDelay - delay time in ms
 * @param {?Object} [_this=undefined] - this to apply to function
 * @returns {function(): Promise<T>}
 */
export function debouncePromise(fn, msDelay, _this = undefined) {
  let timeout;
  return function () {
    return new Promise((resolve, reject) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => fn.apply(_this, arguments).then(resolve, reject), msDelay);
    });
  };
}

/**
 * Extract the error message from a thrown response
 * or return a default message
 * @param e - Error Object
 * @param defaultMessage - a default message in case of no errors
 * @returns {Promise<String>}
 */
export async function extractError(e, defaultMessage) {
  if (e && e.json) {
    const body = await e.json(),
        message = body ?
            body.message || body.error ||
            (body.errors ? body.errors.join(', ') : null)
            : null;
    return message || defaultMessage;
  } else if (e?.message) {
    return e.message;
  } else {
    return defaultMessage;
  }
}

export function randomString(
    length,
    chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') {
  let result = '';
  for (let i = length; i > 0; --i)
    result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

/**
 * Formats and downloads an array of arrays as a text/csv file
 * @param rows - an array of arrays
 * @param fileName - a filename for the downloaded file
 */
export function downloadAsCSV(rows, fileName) {
  const csvContent = rows.map(row =>
      row.map(r => '"' + (r ? r.toString() : '')
              .replace(/"/g, '""')
              .replace(/<p>/g, "")
              .replace(/<\/p>/g, "")
              .replace(/<em>/g, "")
              .replace(/<\/em>/g, "")
              .replace(/<b>/g, "")
              .replace(/<\/b>/g, "")
              .replace(/<i>/g, "")
              .replace(/<\/i>/g, "")
              .replace(/<u>/g, "")
              .replace(/<\/u>/g, "")
              .replace(/<strong>/g, "")
              .replace(/<\/strong>/g, "")
              .replace(/<s>/g, "")
              .replace(/<\/s>/g, "")
              .replace(/<sub>/g, "")
              .replace(/<\/sub>/g, "")
          + '"',
      ).join(','),
  ).join('\r\n');

  const csvBlob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'}),
      csvUrl = URL.createObjectURL(csvBlob);

  const link = document.createElement('a');
  link.href = csvUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * returns the correct suffix of a number, e.g. in 1st, 2nd, 3rd etc
 * @param i - number to get suffix of
 * @returns {string}
 */
export function ordinalSuffixOf(i) {
  let j = i % 10,
      k = i % 100;
  if (j === 1 && k !== 11) {
    return i + 'st';
  }
  if (j === 2 && k !== 12) {
    return i + 'nd';
  }
  if (j === 3 && k !== 13) {
    return i + 'rd';
  }
  return i + 'th';
}

export function formatAddress(address, lineBreak = '\n') {
  const {
    street1 = '', street2 = '', city = '', state = '', zip = '',
  } = address || {};

  return street1 + (street2 ? (lineBreak + street2) : '')
      + lineBreak + city + (state ? (', ' + state) : '') + ' ' + zip;
}

export function formatState(state) {
  switch ((state || '').toUpperCase()) {
    case 'ALABAMA':
      state = 'AL';
      break;
    case 'ALASKA':
      state = 'AL';
      break;
    case 'ARIZONA':
      state = 'AZ';
      break;
    case 'ARKANSAS':
      state = 'AR';
      break;
    case 'CALIFORNIA':
      state = 'CA';
      break;
    case 'COLORADO':
      state = 'CO';
      break;
    case 'CONNECTICUT':
      state = 'CT';
      break;
    case 'DELAWARE':
      state = 'DE';
      break;
    case 'FLORIDA':
      state = 'FL';
      break;
    case 'GEORGIA':
      state = 'GA';
      break;
    case 'HAWAII':
      state = 'HI';
      break;
    case 'IDAHO':
      state = 'ID';
      break;
    case 'ILLINOIS':
      state = 'IL';
      break;
    case 'INDIANA':
      state = 'IN';
      break;
    case 'IOWA':
      state = 'IA';
      break;
    case 'KANSAS':
      state = 'KS';
      break;
    case 'KENTUCKY':
      state = 'KY';
      break;
    case 'LOUISIANA':
      state = 'LA';
      break;
    case 'MAINE':
      state = 'ME';
      break;
    case 'MARYLAND':
      state = 'MD';
      break;
    case 'MASSACHUSETTS':
      state = 'MA';
      break;
    case 'MICHIGAN':
      state = 'MI';
      break;
    case 'MINNESOTA':
      state = 'MN';
      break;
    case 'MISSISSIPPI':
      state = 'MS';
      break;
    case 'MISSOURI':
      state = 'MO';
      break;
    case 'MONTANA':
      state = 'MT';
      break;
    case 'NEBRASKA':
      state = 'NE';
      break;
    case 'NEVADA':
      state = 'NV';
      break;
    case 'NEW HAMPSHIRE':
      state = 'NH';
      break;
    case 'NEW JERSEY':
      state = 'NJ';
      break;
    case 'NEW MEXICO':
      state = 'NM';
      break;
    case 'NEW YORK':
      state = 'NY';
      break;
    case 'NORTH CAROLINA':
      state = 'NC';
      break;
    case 'NORTH DAKOTA':
      state = 'ND';
      break;
    case 'OHIO':
      state = 'OH';
      break;
    case 'OKLAHOMA':
      state = 'OK';
      break;
    case 'OREGON':
      state = 'OR';
      break;
    case 'PENNSYLVANIA':
      state = 'PA';
      break;
    case 'RHODE ISLAND':
      state = 'RI';
      break;
    case 'SOUTH CAROLINA':
      state = 'SC';
      break;
    case 'SOUTH DAKOTA':
      state = 'SD';
      break;
    case 'TENNESSEE':
      state = 'TN';
      break;
    case 'TEXAS':
      state = 'TX';
      break;
    case 'UTAH':
      state = 'UT';
      break;
    case 'VERMONT':
      state = 'VT';
      break;
    case 'VIRGINIA':
      state = 'VA';
      break;
    case 'WASHINGTON':
      state = 'WA';
      break;
    case 'WEST VIRGINIA':
      state = 'WV';
      break;
    case 'WISCONSIN':
      state = 'WI';
      break;
    case 'WYOMING':
      state = 'WY';
      break;
    case 'DISTRICT OF COLUMBIA':
      state = 'DC';
      break;

    default:
      state = (state || '').toUpperCase();
      break;
  }
  return state;
}

export function checkEmail(state, email) {
  // Added formatted section to manage filter and edited report to utilize the formatted version
  console.log('Filter State: ', state)
  console.log('Filter Email: ', email)
    if ((state === 'US-CA') || (state === 'US-CA-DEMO')) {
      if (email.includes('@zingbooks.com')) {
        console.log('filter')
        return '';
      } else {
        return email;
      }
    } else {
      return email;
    }
};


export function formatPhone(phone) {
  //Filter only numbers from the input
  let cleaned = ('' + phone).replace(/\D/g, '');

  let match = [];


  if (cleaned.length === 11) {
    match = cleaned.match(/^(\d)(\d{3})(\d{3})(\d{4})$/);
    return match[1] + '-' + match[2] + '-' + match[3] + '-' + match[4];

  }

  if (cleaned.length === 10) {
    match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match[1] + '-' + match[2] + '-' + match[3];

  }

  if (cleaned.length === 7) {
    match = cleaned.match(/^(\d{3})(\d{4})$/);
    return match[1] + '-' + match[2];

  }


  //Check if the input is of correct length

  // if (match) {
  //   return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  // }

  return null;
}

export function createURL(base, params) {
  const query = Object.entries(params).map(([k, v]) =>
      encodeURIComponent(k) + '=' + encodeURIComponent(v)).join('&');
  return query ? (base + '?' + query) : base;
}

export function addHttpToUrl(url) {
  const test = /^https?:\/\//;
  if (!test.test(url)) {
    return 'http://' + url;
  }

  return url;
}

export function getJSONLocalStorage(key) {
  try {
    return JSON.parse(window.localStorage.getItem(key) || '{}');
  } catch (ignored) {
    return {};
  }
}

export function setJSONLocalStorage(key, value) {
  const oldValue = getJSONLocalStorage(key);
  window.localStorage.setItem(key, JSON.stringify({...oldValue, ...value}));
}

export function useQuery(location) {
  return new URLSearchParams(location.search);
}

export function optionallyConvertQuillToHTML(deltaOps) {
  /*if (isJson(deltaOps)) {
    return new QuillDeltaToHtmlConverter(JSON.parse(deltaOps)['ops'], {inlineStyles: true}).convert();
  }*/

  // If it's not JSON, we assume it's been converted to HTML already
  return deltaOps;
}

/*export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}*/

export function meetsMinAgeRequirement(minAge, birthday, stateSettings) {
  const stateAgeDate = moment(stateSettings.fourHAgeAsOfMonth + "/" + stateSettings.fourHAgeAsOfDay + "/" + (current4HYear(stateSettings) + (stateSettings.fourHAgeAsOfYear || 0)));
  // const age = moment()
  //     .day(stateSettings.ageAsOfDay)
  //     .month(stateSettings.ageAsOfMonth - 1)
  //     .diff(birthday, 'years');
  //     .year(current4HYear(stateSettings) + (stateSettings.ageAsOfYear || 0))
  const age = stateAgeDate.diff(birthday, "y", false);
  return age >= minAge;
}

export function meetsMinAgeRequirementWithAsOfDate(minAge, birthday, ageAsOfDate) {
  const age = moment(ageAsOfDate).diff(birthday, 'years');
  return age >= minAge;
}

export function meetsMaxAgeRequirement(maxAge, birthday, stateSettings) {
  const stateAgeDate = moment(stateSettings.fourHAgeAsOfMonth + "/" + stateSettings.fourHAgeAsOfDay + "/" + (current4HYear(stateSettings) + (stateSettings.fourHAgeAsOfYear || 0)));
  // const age = moment()
  //     .day(stateSettings.ageAsOfDay)
  //     .month(stateSettings.ageAsOfMonth - 1)
  //     .year(current4HYear(stateSettings) + (stateSettings.ageAsOfYear || 0))
  //     .diff(birthday, 'years');
  const age = stateAgeDate.diff(birthday, "y", false);
  return age <= maxAge;
}

export function meetsMaxAgeRequirementWithAsOfDate(maxAge, birthday, ageAsOfDate) {
  const age = moment(ageAsOfDate).diff(birthday, 'years');
  return age <= maxAge;
}

export function validateNumber(input) {
  return !input || /^-?\d*\.?\d+$/g.test(input);
}

export function validateDate(input) {
  const mDate = moment(input);
  return (mDate.isValid() && mDate.year() > 1800);
}

export function validateEmail(input) {
  return /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/gm.test(input);
}

export function validatePhoneNumber(input) {
  const phone_number_with_area = /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/gm;
  const phone_number = /^\d{3}-\d{4}$/gm;
  return phone_number_with_area.test(input) || phone_number.test(input);
}

/**
 * polyfill for browsers that don't support Blob.arrayBuffer()
 *
 * @param {Blob} blob
 * @returns {Promise<ArrayBuffer>}
 */
export async function blobToArrayPolyfill(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('loadend', () => resolve(reader.result));
    reader.addEventListener('error', () => reject(reader.error));
    reader.readAsArrayBuffer(blob);
  });
}

/**
 * Gets a nested element of an object based off of a list of string keys
 *
 * @param {Array<string>} path
 * @param {Object} obj
 *
 * @return {any} - if the nested object is defined and found
 * @return {undefined} - if the nested object doesn't exist
 */
export function getNestedElementByPath(path, obj) {
  return path.reduce((xs, x) => xs?.[x], obj);
}

export function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
}

/**
 * Combine keys in an object based on key aliases
 * @param {Record<string, number>} object
 * @param {Record<string, string>} aliases
 * @returns {Record<string, number>}
 */
export function sumKeyAliases(object, aliases = {}) {
  let result = {};
  for (let key of Object.keys(object || {})) {
    if (aliases[key]) {
      result[aliases[key]] = (result[aliases[key]] || 0) + object[key];
    } else {
      result[key] = (result[key] || 0) + object[key];
    }
  }
  return result;
}

/**
 * Merge demographic object with any aliases, e.g. raceSelect and race
 * @param {string} key - key in the demographic info you want to merge things into
 * @param {Record<string, number>} object - demographic info
 * @param {Record<string, string>} aliases - aliases for the key
 * @returns {Record<string, number>}
 */
export function mergeWithAliases(key, object, aliases = {}) {
  return [
    object[key], ...Object.keys(aliases).filter(k => aliases[k] === key).map(k => object[k])
  ].reduce((result, obj) => mergeObjects(result, obj));
}

/**
 * Merges two objects, summing keys that are the same on both objects
 * @param {Record<string, number>} object1
 * @param {Record<string, number>} object2
 * @returns {Record<string, number>}
 */
export function mergeObjects(object1 = {}, object2 = {}) {
  let result = {...object1};
  for (let key of Object.keys(object2)) {
    if (result[key]) {
      result[key] = result[key] + object2[key];
    } else {
      result[key] = object2[key];
    }
  }
  return result;
}

/**
 * Adds two numbers together with a fixed precision of 2 decimal points.
 * i.e. fixedAdd(0.1, 0.2) returns 0.3 not 0.30000000000000004 (like 0.1 + 0.2 does)
 * @param {number} a
 * @param {number} b
 * @returns {number} a + b
 */
export function fixedAdd(a, b) {
  return (((a * 1000) | 0) + ((b * 1000) | 0)) / 1000;
}

/**
 * formats a number with commas, i.e. 1000 becomes 1,000
 * @param {number} value
 * @returns {string}
 */
export function numberWithCommas(value) {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * Checks if a contest's registration is currently open (using the current time)
 * @param contest {Contest}
 * @param fairEvent {FairEvent}
 * @returns {boolean}
 */
export function isContestRegistrationOpen(contest, fairEvent) {
  const registrationStartsAt = contest.settings.registrationStartsAt
      ? moment(contest.settings.registrationStartsAt) : undefined;
  const registrationClosesAt =
      moment(contest.settings.registrationClosesAt || fairEvent.startsAt);
  const registrationEnabled = contest.settings.enableOnlineRegistration
      || contest.settings.enableOnlineRegistration === undefined;
  return registrationEnabled && (registrationStartsAt ?
      (registrationStartsAt?.isBefore(moment()) && registrationClosesAt.isAfter(moment())) : false);
}

/**
 * Shorten a string if necessary using ellipsis
 * @param {string} text - text to shorten
 * @param {number} maxLength - maximum length of text
 * @returns {string}
 */
export function elipsize(text, maxLength) {
  if(text.length < maxLength) {
    return text;
  } else if(maxLength <= 3) {
    return text.substring(0, maxLength);
  } else {
    return text.substring(0, maxLength - 3) + '...';
  }
}