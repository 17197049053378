import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';

class Spinner extends Component {
  componentDidMount() {
    this.timerId = setTimeout(() => {
      if (!this.props.text) {
        this.setState({text: 'Stuck loading? Try Pressing Ctrl-F5 (Cmd-Shift-F5 on Mac)'});
      }
    }, 20000);
  }

  componentWillUnmount() {
    clearTimeout(this.timerId);
  }

  render() {
    const {text} = this.props;
    return (
        <div style={{textAlign: 'center', marginTop: '4rem', minWidth: 240}}>
          <CircularProgress/> <p>{text || this.state?.text}</p>
        </div>
    );
  }
}

export default Spinner;