import React from 'react';
import { Session } from './components/app/types';

const SessionContext = React.createContext({} as Session);

export const useSession = () => React.useContext(SessionContext);

export function withSession(Component: any) {
  return (props: any) =>
      <SessionContext.Consumer>
        {session => <Component session={session} {...props} />}
      </SessionContext.Consumer>;
}

export default SessionContext;
