import React, { ReactNode, Suspense } from 'react';
import { createBrowserHistory, History } from 'history';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import SessionContext from '../../session_context';
import Spinner from '../spinner';
import '../../../vendor/assets/scss/material-dashboard-pro-react.scss';
import SessionService from '../../services/session_service';
import { alertModal } from '../Modal/Modal';
import AnalyticsSocketClient from '../../services/analytics_socket_client';
import AccountView from '../account_view';
import PasswordReset from '../../pages/password_reset_confirm';
import './styles.css';
import BackendClient from '../../services/backend-client';
import { Session } from './types';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { createTheme, ThemeProvider } from '@mui/material';
import { grayColor, primaryColor, whiteColor } from '../../../vendor/assets/jss/material-dashboard-pro-react';
import Snackbar from '@mui/material/Snackbar';
import NotificationContext from '../../shared/notification';
import Button from '../../../vendor/components/CustomButtons/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';

const HomePage = React.lazy(() => import('../../pages/landing/home'));
const PricingPage = React.lazy(() => import('../../pages/landing/pricing'));
const FairPayPage = React.lazy(() => import('../../pages/landing/fairpay'));
const Register = React.lazy(() => import('../../pages/landing/register'));
const FairRegistration = React.lazy(() => import('../../../fair/FairRegistrationV2'));
const UpdateFairRegistration = React.lazy(() => import('../../../fair/UpdateFairRegistration'));
const VenueLandingPage = React.lazy(() => import('../../../venue/VenueLandingPage'));
const UpdateVenueReservation = React.lazy(() => import('../../../venue/UpdateVenueReservation'));

const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor[0],
      light: primaryColor[1],
      dark: primaryColor[2],
      contrastText: whiteColor,
    },
  },
});

interface AppState {
  sessionContext: Session,
  history: History,
  landingPage: ReactNode,
  message: string | undefined,
}

class App extends React.Component<{}, AppState> {
  constructor(props: {}) {
    super(props);
    const ORG_HOSTS: Record<string, string> = {
      'zfair.org': '4h',
      'ez-fair.com': '4h',
      '4-hshootingsportscollege.com': 'nss',
      'www.4-hshootingsportscollege.com': 'nss',
      'fsf.zsuite.org': 'fsf',
      '4h.zsuite.org': '4h',
      'ffa.zsuite.org': 'ffa',
      'zsuite.clover.academy': '4h',
      'dev.zsuite.org': '4h',
      'dev-zsuite.us-east-2.elasticbeanstalk.com': '4h',
      'staging.zsuite.org': '4h',
      'lms.simplystem.com': 'ss',
      'sassr.zsuite.org': 'sassr',
      'uafext.zsuite.org': 'uafext',
    };

    const initialState: AppState = {
      history: createBrowserHistory(),
      landingPage: undefined,
      sessionContext: {
        settings: undefined as any,
        backendClient: undefined as any,
        account: undefined as any,
        token: undefined as any,
        getSessionService: () => new SessionService(this.state.sessionContext),
        orgId: process.env.REACT_APP_ORG || ORG_HOSTS[window.location.host],
        loadingAccount: true,
        analyticsClient: new AnalyticsSocketClient(),
        scrollToTop: () => {
        },
        updateSession: (session: any) =>
            new Promise(res =>
                this.setState(({sessionContext}) => ({
                  sessionContext: Object.assign({}, sessionContext, session),
                }), res as any),
            ),
        resetSession: (newContext = {}): Promise<void> =>
            new Promise(res =>
                this.setState(({sessionContext}) => ({
                  sessionContext: {
                    ...initialState.sessionContext,
                    backendClient: sessionContext.backendClient,
                    loadingAccount: false, ...newContext,
                  },
                }), res as any),
            ),
      },
      message: undefined,
    };
    this.state = initialState;
  }

  async componentDidMount() {
    this.idleLogout();
    const backendClient = new BackendClient(
        window.fetch.bind(window), process.env.REACT_APP_BACKEND || '/api',
    );

    await new Promise(res => this.setState(({sessionContext}) => ({
      sessionContext:
          Object.assign({}, sessionContext,
              {backendClient}),
    }), res as any));

    await this.state.sessionContext.getSessionService()
        .reloadSession(undefined)
        .catch(() => {
        });

    await this.state.sessionContext.updateSession({
      loadingAccount: false,
    });
  }

  async logout() {
    const {sessionContext: session} = this.state;
    if (!session.account || !session.account.id) {
      return;
    }
    try {
      await session.getSessionService().logout();

      await alertModal('Session Timeout',
          'You have been logged out for inactivity');
    } catch (e) {
      console.error(e);
    }
    this.state.history.push('/');
  }

  idleLogout() {
    let t: number;
    const resetTimer = () => {
      clearTimeout(t);
      t = window.setTimeout(() => {
        if (window.location.pathname.startsWith('/clover-academy/')) {
          return resetTimer();
        } else {
          return this.logout();
        }
      }, process.env.REACT_APP_MAX_IDLE
          ? parseInt(process.env.REACT_APP_MAX_IDLE)
          : 3.6e+6 /* 60 minutes in milliseconds */);
    };
    window.onload = resetTimer;
    window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;  // catches touchscreen presses as well
    window.ontouchstart = resetTimer; // catches touchscreen swipes as well
    window.onclick = resetTimer;      // catches touchpad clicks as well
    window.onkeypress = resetTimer;
    window.addEventListener('scroll', resetTimer, true); // improved; see comments
  }

  render() {
    const {
          sessionContext, history = undefined, sessionContext: {orgId} = {orgId: ''},
          message,
        } = this.state || {}, {loadingAccount, account} = sessionContext || {},
        modifiedHistory = {
          push: (path: string) => {
            history && history.push(path);
            this.forceUpdate();
          },
        };

    if (loadingAccount) {
      return <Spinner/>;
    }

    const commonRoutes = [
      <Route key="home" path="/" exact component={HomePage}/>,
      <Route key="pricing" path="/pricing" exact component={PricingPage}/>,
      <Route key="fairPay" path="/fairpay" exact component={FairPayPage}/>,
      <Route key="register" path="/register" exact component={Register}/>,
      <Route key="fairRegistration" path="/fair-events/:fairEventId/register" exact component={FairRegistration}/>,
      <Route key="updateFairRegistration" path="/fair-events/:fairEventId/update-registration"
             exact component={UpdateFairRegistration}/>,
      <Route key="venueLandingPage" path="/venues/:venueId/reserve" exact component={VenueLandingPage}/>,
      <Route key="updateVenueRegistration" path="/venues/:venueId/update-reservation"
             exact component={UpdateVenueReservation}/>,
    ];

    // @ts-ignore
    let view = <Switch>
      <Route path="/password-reset/:token" component={PasswordReset}/>
      {commonRoutes}
      <Route>
        <Redirect to="/"/>
      </Route>
    </Switch>;

    if (account && account.id) {
      view = <Switch>
        {commonRoutes}
        <Route>
          <AccountView orgId={orgId} account={account}/>
        </Route>
      </Switch>;
    }

    // Special case for password reset routes, using <Switch /> causes reloading loop
    if (window.location.pathname.startsWith('/password-reset/')) {
      let result;
      result = window.location.pathname.match(
          /\/password-reset\/(.*)$/);
      return <PasswordReset session={sessionContext}
                            history={modifiedHistory}
                            match={{params: {token: result ? result[1] : ''}}}/>;
    }
    const showViewAs = window.document.cookie.includes('VIEW_AS_MARKER=1');

    return <NotificationContext.Provider value={({message}) => this.setState({message})}>
      <SessionContext.Provider value={sessionContext}>
        <Suspense fallback={<Spinner/>}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              {showViewAs ? <div style={{
                position: 'sticky',
                top: 0,
                width: '100%',
                zIndex: 1040,
                color: 'white',
                background: grayColor[2],
                padding: '0.25rem 0'
              }}>
                <Button color="primary" style={{margin: '.24rem 1rem'}} onClick={async () => {
                  await sessionContext.backendClient.resetViewAsAccount({});
                  window.location.reload();
                }}>
                  <KeyboardArrowLeft/> Back
                </Button>
                <span>
            Showing View for <strong>{account?.firstName} {account?.lastName}</strong>
          </span>
              </div> : ''}
              <Router history={history as any}>{view}</Router>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Suspense>
      </SessionContext.Provider>
      <Snackbar open={!!message} onClose={() => this.setState({message: ''})} message={message}
                autoHideDuration={5000}/>;
    </NotificationContext.Provider>;
  }
}

export default App;
