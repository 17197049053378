import React, { Component } from 'react';
import { withSession } from '../session_context';
import GridItem from '../../vendor/components/Grid/GridItem';
import GridContainer from '../../vendor/components/Grid/GridContainer';
import zfairLogo from '../shared/img/EZ Fair Final V1.png';
import Button from '../../vendor/components/CustomButtons/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import SnackbarContent from '../../vendor/components/Snackbar/SnackbarContent';
import Card from '../../vendor/components/Card/Card';
import CustomFormInput from '../components/form_data/custom_form_input';
import Spinner from '../components/spinner';
import PropTypes from 'prop-types';
import { FormData } from '../components/form_data';

const styles = {
  cardImgTop: {
    width: '65%',
    maxWidth: 240,
    borderTopLeftRadius: 'calc(.25rem - 1px)',
    borderTopRightRadius: 'calc(.25rem - 1px)',
    margin: 'auto',
  },
  center: {
    margin: 'auto',
  },
  header: {
    textAlign: 'center',
    fontSize: '140%',
    marginTop: '1rem',
  },
};

class PasswordReset extends Component {

  async componentDidMount() {
    const {match: {params: {token}}} = this.props;
    const {session: {backendClient}} = this.props;
    try {
      const {resetPin} = await backendClient.verifyPasswordResetToken({token});
      this.setState({isValid: true, token, resetPin});
    } catch (e) {
      console.log('error', e);
      this.setState({isValid: false, token});
    }
  }

  async resetPassword() {
    const {password, confirm_password, token} = this.state || {};
    const {session: {backendClient, getSessionService}, history} = this.props;

    if (password !== confirm_password) {
      this.setState({passwordError: 'Passwords do not match'});
    } else {
      try {
        await backendClient.confirmPasswordReset({}, {
          newPassword: password, token,
        });
        this.setState({
          password: undefined,
          confirm_password: undefined,
        });
        await getSessionService().reloadSession();
        history.push('/');
      } catch (e) {
        console.error(e);
        this.setState({
          passwordError: 'An error occurred while trying to save the data',
        });
      }

    }

  }

  render() {
    const {classes} = this.props;
    const {passwordError, isValid, resetPin} = this.state || {},
        label = resetPin ? 'Pin' : 'Password';
    if (isValid === undefined) {
      return <Spinner/>;
    }
    return (
        <div style={{justifyItems: 'center'}}>
          <GridContainer justify='center'>
            <GridItem md={4} lg={4}>
              <div>
                <Card>
                  <img
                      className={classes.cardImgTop}
                      src={zfairLogo}
                      alt="EZFair Logo"/>

                  {!isValid && <h5 align='center'>This token has expired.</h5>}
                  {isValid && <div>
                    <p className={classes.header}>
                      Finish Resetting your Account {label}
                    </p>
                    <FormData data={this.state}
                              onChange={this.setState.bind(this)}>
                      <GridItem xs={12} md={8} className={classes.center}>
                        <CustomFormInput id="password" labelText={label}
                                         type="password"/>
                      </GridItem>
                      <GridItem xs={12} md={8} className={classes.center}>
                        <CustomFormInput id="confirm_password"
                                         labelText={`Confirm ${label}`}
                                         type="password"/>
                      </GridItem>
                    </FormData>
                    <GridItem align='center'>
                      <Button color="info"
                              onClick={() => this.resetPassword(
                                  this)}>Reset</Button>
                    </GridItem>
                    {passwordError && <SnackbarContent
                        message={passwordError}
                        onClose={() => this.setState({passwordError: false})}
                        close
                        color="danger"
                    />}
                  </div>}


                </Card>
              </div>
            </GridItem>
          </GridContainer>
        </div>
    );
  }
}

PasswordReset.propTypes = {
  history: PropTypes.any,
};

export default withSession(withStyles(styles)(PasswordReset));